import { actionTypes } from "../actions";
const initialState = {
  loading: false,
  payment: [],
  error: null,
};
const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_PAYMENT_SUCCESS:
      console.log("Payment from reducer", action.payload);
      return {
        ...state,
        loading: false,
        payment: action.payload,
      };
    default:
      return state;
  }
};
export default paymentReducer;
