import { actionTypes, actions } from "../actions";
const initialState = {
  loading: false,
  error: null,
  records: [],
};
const storeRecordReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_STORE_RECORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload,
      };
    case actionTypes.ADD_STORE_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        records: [...state.records, action.payload],
      };
    case actionTypes.DELETE_STORE_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        records: state.records.filter((record) => record.id !== action.payload),
      };
    default:
      return state;
  }
};
export default storeRecordReducer;
