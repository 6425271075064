import { takeLatest, put, all, call } from "redux-saga/effects";
import { actions, actionTypes } from "../actions";
import { auth } from "../../firebase/utils";
import {
  getDatabase,
  ref,
  get,
  child,
  set,
  push,
  serverTimestamp,
  remove,
  onValue,
  update,
} from "firebase/database";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  uploadBytes,
} from "firebase/storage";
import { ref as sRef } from "firebase/storage";
import showToast from "../../utils/functions";
import { app } from "../../firebase/utils/auth";

const db = getDatabase();

const storage = getStorage(app);
function* geAlltNotices({ payload }) {
  try {
    // const noticeRef = ref(db, `all_notice_circular/${payload}`);
    const noticeSnapshot = yield get(child(ref(db), `all_notice_circular`));
    const notice = yield noticeSnapshot.val()
      ? Object.keys(noticeSnapshot.val()).map((key) => ({
          ...noticeSnapshot.val()[key],
        }))
      : [];

    yield console.log("Important ===============>>", notice);
    yield put(actions.getAllNoticesSuccess(notice));
  } catch (err) {
    console.log(err);
  }
}

function* addNotice({ payload }) {
  yield put(actions.startLoading());
  try {
    const { title, description, file, withCheckedFields, clearAllFields } =
      payload;
    const newNoticeKey = push(ref(db, `all_notice_circular`)).key;
    const classIds = [];
    if (withCheckedFields.length > 0) {
      withCheckedFields.forEach((item) => {
        if (item.checked) {
          classIds.push(item?.classId);
        }
      });
    }
    const notice = {
      subject: title,
      description,
      createdAt: serverTimestamp(),
      classIds,
      noticeId: newNoticeKey,
      fileUrl: "",
    };
    const updates = {};

    updates[`all_notice_circular/${newNoticeKey}`] = notice;

    if (withCheckedFields.length > 0) {
      withCheckedFields.forEach((item) => {
        if (item.checked) {
          updates[`notice_circular/${item?.classId}/${newNoticeKey}`] = notice;
        }
      });
    }

    update(ref(db), updates);

    //upload file to storage
    if (file) {
      const storageRef = sRef(storage, `notice_circular/${newNoticeKey}`);
      yield uploadBytes(storageRef, file);
      const downloadURL = yield getDownloadURL(storageRef);
      notice.fileUrl = downloadURL;
    }
    yield set(ref(db, `all_notice_circular/${newNoticeKey}`), notice);
    yield put(actions.getAllNotices());
    yield put(actions.stopLoading());
    clearAllFields();
    showToast("Notice posted successfully", "success");
  } catch (err) {
    yield put(actions.stopLoading());
    console.log(err);
  }
}

// function* handleDeleteNotice({ payload }) {
//   const { noticeId, classIds, noticeCreatedBy } = payload;
//   const userId = yield select((state) => state.userReducer?._id);
//   if (userId !== noticeCreatedBy) {
//     return yield Alert.alert(
//       "Error",
//       "You are not allowed to delete this notice."
//     );
//   }
//   yield put(actions.noticeLoading(true));
//   try {
//     const updates = {};
//     updates[`all_notice_circular/${noticeId}`] = null;
//     yield classIds.forEach(async (item) => {
//       updates[`notice_circular/${item}/${noticeId}`] = null;
//     });
//     yield firebase.database().ref().update(updates);
//     yield put(actions.deleteNoticeSuccess(noticeId));
//     yield put(actions.noticeLoading(false));
//     yield Alert.alert("Success", "Your notice has been deleted.");
//   } catch (error) {
//     yield Alert.alert("Error", error.message);
//     yield put(actions.noticeLoading(false));
//   }
//   yield put(actions.noticeLoading(false));
// }

function* deleteNotice({ payload }) {
  try {
    const { classIds, noticeId } = payload;
    const updates = {};
    updates[`all_notice_circular/${noticeId}`] = null;
    yield classIds.forEach(async (item) => {
      updates[`notice_circular/${item}/${noticeId}`] = null;
    });
    yield update(ref(db), updates);
    // yield remove(ref(db, `all_notice_circular/${noticeId}`));
    const storageRef = sRef(storage, `notice_circular/${noticeId}`);
    yield deleteObject(storageRef);

    yield put(actions.getAllNotices());
    showToast("Notice deleted successfully", "success");
  } catch (err) {
    console.log(err);
    showToast("Something went wrong", "error");
  }
}
function* editNotice({ payload }) {
  try {
    yield put(actions.startLoading());
    const clearEditFields = payload.clearEditFields;
    const file = payload.file;
    delete payload.clearEditFields;
    delete payload.file;

    const { noticeId: id } = payload;
    const noticeRef = ref(db, `all_notice_circular/${id}`);
    const extractedPayload = Object.keys(payload).reduce((acc, key) => {
      if (payload[key] !== "") {
        acc[key] = payload[key];
      }
      return acc;
    }, {});
    yield console.log("EXTRACTED PAYLOAD", extractedPayload);
    yield extractedPayload != {} && update(noticeRef, extractedPayload);
    if (file != "") {
      const storageRef = sRef(storage, `notice_circular/${id}`);

      yield uploadBytes(storageRef, file);
      const downloadURL = yield getDownloadURL(storageRef);
      yield update(noticeRef, { fileUrl: downloadURL });
    }
    yield put(actions.getAllNotices());
    yield put(actions.stopLoading());
    clearEditFields();
    showToast("Notice updated successfully", "success");
  } catch (err) {
    yield put(actions.stopLoading());
    showToast("Something went wrong", "error");
    console.log(err);
  }
}

export default function* noticeSaga() {
  yield takeLatest(actionTypes.GET_ALL_NOTICES, geAlltNotices);
  yield takeLatest(actionTypes.ADD_NOTICE, addNotice);
  yield takeLatest(actionTypes.DELETE_NOTICE, deleteNotice);
  yield takeLatest(actionTypes.EDIT_NOTICE, editNotice);
}
