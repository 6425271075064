import React from "react";
import "./Updates.css";
// import { Noticesdata } from "../../Data/Data";
import Avatar from "react-avatar";
import { useNoticeSelector } from "../../redux/selectors";
import ScrollbarWrapper from "../ScrollbarWrapper";
const Notices = () => {
  const Noticesdata = useNoticeSelector();
  return (
    // <ScrollbarWrapper>
    <div className="Updates">
      {Noticesdata.map((notice) => {
        return (
          <div key={notice.noticeId} className="update">
            <Avatar name={"UKP"} size={35} round={true} />
            {/* <img src={notice.img} alt="profile" /> */}
            <div className="noti">
              <div style={{ marginBottom: "0.5rem" }}>
                <span>{notice.subject}</span>
                <br />
                <span>
                  {" "}
                  {notice.description.length
                    ? notice.description.slice(0, 50) + "..."
                    : notice.description}
                </span>
              </div>
              <span>
                {" "}
                {new Date(notice.createdAt).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
            </div>
          </div>
        );
      })}
    </div>
    // </ScrollbarWrapper>
  );
};

export default Notices;
