import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { actions, actionTypes } from "../actions";
import { auth } from "../../firebase/utils";
import {
  getDatabase,
  ref,
  get,
  child,
  set,
  push,
  serverTimestamp,
  remove,
  onValue,
  update,
} from "firebase/database";
import showToast from "../../utils/functions";
import { Navigate, NavLink, redirect, useNavigate } from "react-router-dom";

const db = getDatabase();
function* getAllPayments() {
  try {
    const snapshot = yield call(get, ref(db, "students_payment"));
    const payments = yield snapshot.val()
      ? Object.keys(snapshot.val()).map((key) => ({
          ...snapshot.val()[key],
          _id: key,
        }))
      : [];
    yield put(actions.getAllPaymentsSuccess(payments));
  } catch (error) {
    showToast(error.message, "error");
  }
}

function* handleSetMonthlyFeesForAClass({ payload }) {
  const { classId, amount } = payload;
  console.log(payload);
  try {
    yield call(set, ref(db, `class_rooms/${classId}/monthly_fees`), amount);
    yield put(actions.editClassSuccess({ classId, monthly_fees: amount }));
    showToast("Monthly Fees updated successfully...", "success");
  } catch (error) {
    showToast(error.message, "error");
  }
}

export default function* paymentSaga() {
  yield takeLatest(actionTypes.GET_ALL_PAYMENT, getAllPayments);
  yield takeLatest(
    actionTypes.SET_MONTHLY_FEES_FOR_A_CLASS,
    handleSetMonthlyFeesForAClass
  );
}
