import { actionTypes } from "../actions";
const initialState = {
  notices: [],
  loading: false,
  error: null,
};
const noticeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_NOTICES_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        notices: action.payload.reverse(),
        loading: false,
      };
    case actionTypes.ADD_NOTICE_SUCCESS:
      return {
        ...state,
        notices: {
          ...state.notices,
          [action.payload.classId]: [
            ...(state.notices[action.payload.classId] || []),
            action.payload,
          ],
        },
        loading: false,
      };
    case actionTypes.DELETE_NOTICE_SUCCESS:
      return {
        ...state,
        notices: {
          ...state.notices,
          [action.payload.classId]: state.notices[
            action.payload.classId
          ].filter((item) => item.id !== action.payload.id),
        },
        loading: false,
      };
    case actionTypes.EDIT_NOTICE_SUCCESS:
      return {
        ...state,
        notices: {
          ...state.notices,
          [action.payload.classId]: state.notices[action.payload.classId].map(
            (item) => (item.id === action.payload.id ? action.payload : item)
          ),
        },
        loading: false,
      };

    default:
      return state;
  }
};
export default noticeReducer;
