import React, { Suspense, useTransition } from "react";
import MainDash from "../components/MainDash/MainDash";
import RightSide from "../components/RigtSide/RightSide";
import ScrollbarWrapper from "../components/ScrollbarWrapper";
import Loading from "../utils/Loading";

function Dashboard() {
  const [startTransition, isPending] = useTransition();
  return (
    <>
      <Suspense fallback={<Loading />}>
        <ScrollbarWrapper>
          <MainDash />
        </ScrollbarWrapper>
      </Suspense>

      <Suspense fallback={<Loading />}>
        <ScrollbarWrapper>
          <RightSide />
        </ScrollbarWrapper>
      </Suspense>
    </>
  );
}

export default Dashboard;
