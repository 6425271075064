import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { actions, actionTypes } from "../actions";
import { auth } from "../../firebase/utils";
import {
  getDatabase,
  ref,
  get,
  child,
  set,
  push,
  serverTimestamp,
  remove,
  onValue,
  update,
} from "firebase/database";
import showToast from "../../utils/functions";
import { Navigate, NavLink, redirect, useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import dayjs from "dayjs";
const db = getDatabase();

function* getAllStoreRecords() {
  try {
    yield put(actions.startLoading());
    const snapshot = yield get(child(ref(db), `store_records`));
    const storeRecords = snapshot.val()
      ? Object.keys(snapshot.val()).map((key) => ({
          ...snapshot.val()[key],
        }))
      : [];
    yield put(actions.getAllStoreRecordsSuccess(storeRecords));
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(actions.stopLoading());
    console.log(error);
  }
}
function* addStoreRecord({ payload }) {
  try {
    yield put(actions.startLoading());
    const key = push(child(ref(db), `store_records`)).key;
    yield set(child(ref(db), `store_records/${key}`), {
      ...payload,
      full_name: payload.full_name,
      recordId: key,
      status:
        payload.amountReceived === payload.productPrice
          ? "Paid"
          : payload.amountReceived > 0
          ? "Partially Paid"
          : "Unpaid",
      createdAt: serverTimestamp(),
    });
    // yield put(
    //   actions.addStoreRecordSuccess({
    //     ...payload,
    //     recordId: key,
    //     createdAt: serverTimestamp(),
    //   })
    // );
    // yield put(actions.stopLoading());
    // yield put(actions.getAllStoreRecords());
    showToast("Store Record Added Successfully", "success");
  } catch (error) {
    yield put(actions.stopLoading());
    console.log(error);
  }
}

export default function* storeRecordSaga() {
  yield takeLatest(actionTypes.GET_ALL_STORE_RECORDS, getAllStoreRecords);
  yield takeLatest(actionTypes.ADD_STORE_RECORD, addStoreRecord);
}
