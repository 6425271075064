import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { actions, actionTypes } from "../actions";
import { auth } from "../../firebase/utils";
import {
  getDatabase,
  ref,
  get,
  child,
  set,
  push,
  serverTimestamp,
  remove,
  onValue,
  update,
} from "firebase/database";
import showToast from "../../utils/functions";
import { Navigate, NavLink, redirect, useNavigate } from "react-router-dom";

const db = getDatabase();
function* addClass({ payload }) {
  try {
    // yield put(actions.addClassSuccess(classRoom));
    const key = push(ref(db, "class_rooms")).key;
    const classRoom = {
      ...payload,
      classId: key,
      createdAt: serverTimestamp(),
    };
    // yield Set(ref(db, `class_rooms/${key}`), classRoom);
    yield set(ref(db, `class_rooms/${key}`), classRoom);
    yield put(actions.addClassSuccess(classRoom));
    yield console.log(classRoom);
    yield showToast("Class Added Successfully", "success");
  } catch (error) {
    // console.log(error);
    showToast("error", "error");
    console.log(error);
  }
}
function* getAllClasses() {
  try {
    const snapshot = yield get(child(ref(db), `class_rooms`));
    // make it an array
    const classes = snapshot.val()
      ? Object.keys(snapshot.val()).map((key) => ({
          ...snapshot.val()[key],
          classId: key,
        }))
      : [];
    // yield console.log("Classes from saga", classes);
    yield put(actions.getAllClassesSuccess(classes));
  } catch (error) {
    console.log(error);
  }
}

function* editClass({ payload }) {
  try {
    // extract all item from the payload which is not null
    const extractedPayload = Object.keys(payload).reduce((acc, key) => {
      if (payload[key] !== "") {
        acc[key] = payload[key];
      }
      return acc;
    }, {});
    yield update(ref(db, `class_rooms/${payload.classId}`), extractedPayload);
    yield put(actions.editClassSuccess(extractedPayload));
    showToast("Class Edited Successfully", "success");
  } catch (error) {
    showToast("Error Editing Class", "error");
  }
}
function* deleteClass({ payload }) {
  try {
    yield remove(ref(db, `class_rooms/${payload.classId}`));
    yield put(actions.deleteClassSuccess(payload.classId));
    showToast("Class Deleted Successfully", "success");
  } catch (error) {
    console.log(error);
  }
}

export default function* watchClassRoom() {
  yield takeLatest(actionTypes.ADD_CLASS, addClass);
  yield takeLatest(actionTypes.GET_ALL_CLASSES, getAllClasses);
  yield takeLatest(actionTypes.EDIT_CLASS, editClass);
  yield takeLatest(actionTypes.DELETE_CLASS, deleteClass);
}
