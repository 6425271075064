import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./userReducer";
import classRoomReducer from "./classRoomReducer";
import studentsReducer from "./studentsReducer";
import teachersReducer from "./teachersReducer";
import noticeReducer from "./noticeReducer";
import attendanceReducer from "./attendanceReducer";
import paymentReducer from "./paymentReducer";
import eventsReducer from "./eventsReducer";
import storeRecordReducer from "./storeRecordReducer";
const userReducerConfig = {
  key: "user",
  storage,
  blacklist: ["loading"],
};
const classRoomReducerConfig = {
  key: "class",
  storage,
  blacklist: ["loading"],
};
const studentsReducerConfig = {
  key: "students",
  storage,
  blacklist: ["loading"],
};
const teachersReducerConfig = {
  key: "teachers",
  storage,
  blacklist: ["loading"],
};
const noticeReducerConfig = {
  key: "notice",
  storage,
  blacklist: ["loading"],
};
const attendanceReducerConfig = {
  key: "attendance",
  storage,
  blacklist: ["loading"],
};
const paymentReducerConfig = {
  key: "payment",
  storage,
  blacklist: ["loading"],
};
const eventsReducerConfig = {
  key: "events",
  storage,
  blacklist: ["loading"],
};

const rootReducer = combineReducers({
  userReducer: persistReducer(userReducerConfig, userReducer),
  classRoomReducer: persistReducer(classRoomReducerConfig, classRoomReducer),
  studentsReducer: persistReducer(studentsReducerConfig, studentsReducer),
  teachersReducer: persistReducer(teachersReducerConfig, teachersReducer),
  noticeReducer: persistReducer(noticeReducerConfig, noticeReducer),
  attendanceReducer: persistReducer(attendanceReducerConfig, attendanceReducer),
  paymentReducer: persistReducer(paymentReducerConfig, paymentReducer),
  eventsReducer: eventsReducer,
  storeRecordReducer: storeRecordReducer,
});
export default rootReducer;
