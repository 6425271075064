import { takeLatest, put, all, call } from "redux-saga/effects";

import { actions, actionTypes } from "../actions";
import { auth } from "../../firebase/utils";
import {
  getDatabase,
  ref,
  get,
  child,
  set,
  push,
  serverTimestamp,
  remove,
  onValue,
  update,
} from "firebase/database";
import showToast from "../../utils/functions";
import { Navigate, NavLink, redirect, useNavigate } from "react-router-dom";
import { URLS } from "../../utils/urls";

const db = getDatabase();
const url = URLS.REACT_APP_API_URL_SIGNUP;
function* addStudents({ payload }) {
  try {
    const handleClose = payload.handleClose;
    delete payload.handleClose;
    yield put(actions.startLoading());
    const response = yield call(fetch, url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...payload, user_type: "student" }),
    });
    const data = yield response.json();
    if (data.data.userData) {
      showToast("Student Added Successfully", "success");
      yield update(ref(db, `students/${data.data.userData._id}`), {
        classId: payload.classId,
      });
      yield set(
        ref(
          db,
          `class_rooms/${payload.classId}/students/${data.data.userData._id}`
        ),
        {
          enrollmentId: data.data.userData.enrollmentId,
          studentId: data.data.userData._id,
        }
      );
      yield put(
        actions.assignStudentToClassSuccess({
          classId: payload.classId,
          studentId: data.data.userData._id,
          enrollmentId: data.data.userData.enrollmentId,
        })
      );
      yield put(
        actions.addStudentSuccess({
          ...data.data.userData,
          classId: payload.classId,
        })
      );
      yield handleClose();
    } else {
      showToast("Student Already Exist", "error");
    }
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(actions.stopLoading());
    console.log(error);
  }
}
function* getAllStudents({ payload }) {
  try {
    const response = yield get(child(ref(db), `students`));
    const responseArr = yield response.val()
      ? Object.keys(response.val()).map((key) => ({
          ...response.val()[key],
        }))
      : [];
    yield put(actions.getAllStudentsSuccess(responseArr));
    yield console.log("Data==>", responseArr);
  } catch (error) {
    console.log(error);
  }
}
function* deleteStudent({ payload }) {
  try {
    yield remove(ref(db, `students/${payload}`));
    // yield remove(ref(db, `credentials/${payload}`));
    yield showToast("Student Deleted Successfully", "success");
    yield put(actions.deleteStudentSuccess(payload));
  } catch (error) {
    console.log(error);
  }
}
function* editStudent({ payload }) {
  try {
    const handleClose = payload.handleClose;
    delete payload.handleClose;
    delete payload.id;
    const extractedPayload = Object.keys(payload).reduce((acc, key) => {
      if (payload[key] !== "") {
        acc[key] = payload[key];
      }
      return acc;
    }, {});
    yield update(ref(db, `students/${payload._id}`), extractedPayload);
    yield showToast("Student Updated Successfully", "success");
    yield put(actions.editStudentSuccess(extractedPayload));
    handleClose();
  } catch (error) {
    console.log(error);
  }
}
export default function* watchStudents() {
  yield takeLatest(actionTypes.ADD_STUDENT, addStudents);
  yield takeLatest(actionTypes.GET_ALL_STUDENTS, getAllStudents);
  yield takeLatest(actionTypes.DELETE_STUDENT, deleteStudent);
  yield takeLatest(actionTypes.EDIT_STUDENT, editStudent);
}
