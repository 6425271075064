import { Button } from "@mui/material";
import jsPDF from "jspdf";
import React from "react";
import "./invoice.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Logo from "../imgs/logo.png";
import Signature from "../imgs/download.png";
const { forwardRef, useRef, useImperativeHandle } = React;
const Invoice = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    generatePDF: () => {
      generatePDF();
    },
  }));
  const generatePDF = () => {
    //compress the pdf

    const report = new jsPDF("portrait", "pt", "a4", true);
    report.addImage(Logo, "PNG", 0, 0, 0, 0, undefined, "FAST");
    report.html(document.querySelector("#Test")).then(() => {
      report?.deletePage(2);
      report.save("report.pdf");
    });
  };
  return (
    <>
      <div id="Test" className="invoiceContiner">
        <div className="invoice">
          <div className="invoice__header">
            <h1>Invoice</h1>
            <div className="invoice__header__logo">
              <img
                style={{ width: "40px", height: "auto" }}
                src={Logo}
                alt="logo"
              />
            </div>
          </div>
          <div className="invoice__header__info">
            <p>Invoice # 123456</p>
            <p>Invoice Date: 12/12/2021</p>
          </div>
          <div className="invoice__body">
            <div className="invoice__body__info">
              <div className="invoice__body__info__left">
                <h3>To</h3>
                <div style={{ fontSize: "13px" }}>
                  <p>Universal Knowledge Park School</p>
                  <p>Assam</p>
                  <p>Madhurbond Silchar, 740025</p>
                  <p>Phone: 123-456-7890</p>
                  <p>
                    Email: Ukp@mail.com
                    <a
                      href="mailto:
                        "
                    ></a>
                  </p>
                </div>
              </div>
              <div className="invoice__body__info__right">
                <h3>From</h3>
                <div style={{ fontSize: "13px" }}>
                  <p>Biproraj Das</p>
                  <p>Guwahati, Narengi</p>

                  <p>Phone: 123-456-7890</p>
                  <p>
                    Email: BiprorajDas78788@gmail.com
                    <a
                      href="mailto:
                        "
                    ></a>
                  </p>
                </div>
              </div>
            </div>
            <div className="invoice__body__table">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow className="table_header">
                      <TableCell>Item</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Item 1</TableCell>
                      <TableCell>Description 1</TableCell>
                      <TableCell>1</TableCell>
                      <TableCell>$100</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Item 2</TableCell>
                      <TableCell>Description 2</TableCell>
                      <TableCell>2</TableCell>
                      <TableCell>$200</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Item 3</TableCell>
                      <TableCell>Description 3</TableCell>
                      <TableCell>3</TableCell>
                      <TableCell>$300</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="invoice__body__total">
              <div className="invoice__body__total__left">
                <p>Subtotal:</p>
                <p>Tax:</p>
                <p>Total:</p>
              </div>
              <div className="invoice__body__total__right">
                <p>$600</p>
                <p>$60</p>
                <p>$660</p>
              </div>
            </div>
          </div>
          <hr />
          <div className="invoice__footer">
            <img
              src={Signature}
              style={{ height: "20px", width: "auto" }}
              alt="signature"
            />
            <p>Signature</p>
          </div>
        </div>
      </div>
      {/* <Button onClick={generatePDF}>Download</Button> */}
    </>
  );
});

export default Invoice;
