import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { actions, actionTypes } from "../actions";
import { auth } from "../../firebase/utils";
import {
  getDatabase,
  ref,
  get,
  child,
  set,
  push,
  serverTimestamp,
  remove,
  onValue,
  update,
} from "firebase/database";
import showToast from "../../utils/functions";
import { Navigate, NavLink, redirect, useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import dayjs from "dayjs";
const db = getDatabase();

function* getAllEvents() {
  try {
    yield put(actions.startLoading());
    const snapshot = yield get(child(ref(db), `events`));
    const events = snapshot.val()
      ? Object.keys(snapshot.val()).map((key) => ({
          ...snapshot.val()[key],
        }))
      : [];
    yield events.forEach((event) => {
      event.start = new Date(event.start);
      event.end = new Date(event.end);
    });
    yield put(actions.getAllEventsSuccess(events));
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(actions.stopLoading());
    console.log(error);
  }
}
function* addEvents({ payload }) {
  try {
    const setNewEvent = payload.setNewEvent;
    delete payload.setNewEvent;

    yield console.log(payload);
    const eventKey = yield push(ref(db, `events`)).key;
    const { title, description, start, end, color } = payload;
    // convert date to timestamp
    const startTimestamp = yield new Date(start).getTime();
    const endTimestamp = yield new Date(end).getTime();
    const event = yield {
      event_id: eventKey,
      title,
      description,
      start: startTimestamp,
      end: endTimestamp,
      color,
    };
    yield set(ref(db, `events/${eventKey}`), event);
    yield put(actions.getAllEvents());
    yield setNewEvent(eventKey);
  } catch (error) {
    console.log(error);
  }
}
function* editEvents({ payload }) {
  try {
    const setNewEvent = payload.setNewEvent;
    delete payload.setNewEvent;
    const { title, description, start, end, event_id } = payload;
    // convert date to timestamp
    const startTimestamp = yield new Date(start).getTime();
    const endTimestamp = yield new Date(end).getTime();
    const event = yield {
      event_id,
      title,
      description,
      start: startTimestamp,
      end: endTimestamp,
    };
    yield update(ref(db, `events/${event_id}`), event);
    yield put(actions.getAllEvents());
    yield setNewEvent(event_id);
  } catch (error) {
    console.log(error);
  }
}
function* deleteEvent({ payload }) {
  try {
    const { event_id } = payload;
    yield remove(ref(db, `events/${event_id}`));
    yield put(actions.getAllEvents());
  } catch (error) {
    console.log(error);
  }
}
export default function* eventsSaga() {
  yield takeLatest(actionTypes.GET_ALL_EVENTS, getAllEvents);
  yield takeLatest(actionTypes.ADD_EVENT, addEvents);
  yield takeLatest(actionTypes.EDIT_EVENT, editEvents);
  yield takeLatest(actionTypes.DELETE_EVENT, deleteEvent);
}
