import { actions, actionTypes } from "../actions";
const initialState = {
  teachers: [],
  loading: false,
  error: null,
};
const teachersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_TEACHERS_SUCCESS:
      console.log("done");
      return {
        ...state,
        teachers: action.payload,
        loading: false,
      };
    case actionTypes.ADD_TEACHER_SUCCESS:
      return {
        ...state,
        teachers: [action.payload, ...state.teachers],
        loading: false,
      };
    case actionTypes.DELETE_TEACHER_SUCCESS:
      console.log("Deleted", action.payload);
      return {
        ...state,
        teachers: state.teachers.filter(
          (teacher) => teacher._id !== action.payload
        ),
        loading: false,
      };
    case actionTypes.EDIT_TEACHER_SUCCESS:
      return {
        ...state,
        teachers: state.teachers.map((teacher) =>
          teacher._id === action.payload._id
            ? { ...teacher, ...action.payload }
            : teacher
        ),
        loading: false,
      };
    case actionTypes.CLASS_ASSIGNED_SUCCESS:
      return {
        ...state,
        teachers: state.teachers.map((teacher) =>
          teacher.enrollmentId === action.payload.teacherId
            ? {
                ...teacher,
                classes_assigned: { subjectId: action.payload.subjectId },
              }
            : teacher
        ),
        loading: false,
      };

    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default teachersReducer;
