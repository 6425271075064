import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC3Ha59nY5lHOM-46V1Axsa1TNsdmw9vlQ",
  authDomain: "ukpschoolsystem.firebaseapp.com",
  databaseURL: "https://ukpschoolsystem-default-rtdb.firebaseio.com",
  projectId: "ukpschoolsystem",
  storageBucket: "ukpschoolsystem.appspot.com",
  messagingSenderId: "584031605035",
  appId: "1:584031605035:web:d5f86c5d5a6bfe52b1356e",
  measurementId: "G-VJM85VKPQK",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;
