import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { actions, actionTypes } from "../actions";
import { auth } from "../../firebase/utils";
import {
  getDatabase,
  ref,
  get,
  child,
  set,
  push,
  serverTimestamp,
  remove,
  onValue,
  update,
} from "firebase/database";
import showToast from "../../utils/functions";
import { Navigate, NavLink, redirect, useNavigate } from "react-router-dom";

const db = getDatabase();
function* getAllAttendance({ payload }) {
  try {
    const snapshot = yield get(child(ref(db), `attendance_list/${payload}`));
    const attendance = snapshot.val()
      ? Object.keys(snapshot.val()).map((key) => ({
          ...snapshot.val()[key],
          attendanceId: key,
        }))
      : [];
    yield put(actions.getAllAttendanceSuccess(attendance));
  } catch (error) {
    console.log(error);
  }
}
export default function* attendanceSaga() {
  yield takeLatest(actionTypes.GET_ALL_ATTENDANCE, getAllAttendance);
}
