import { actionTypes } from "../actions";
const initialState = {
  user: {},
  loading: false,
  error: null,
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGNUP_SUCCESS:
      console.log("SIGNUP_SUCCESS");
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        user: {},
      };
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
export default userReducer;
