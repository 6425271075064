import actionTypes from "./actionTypes";
const actions = {
  signup: (payload) => ({
    type: actionTypes.SIGNUP,
    payload,
  }),
  login: (payload) => ({
    type: actionTypes.LOGIN,
    payload,
  }),
  signupSuccess: (payload) => ({
    type: actionTypes.SIGNUP_SUCCESS,
    payload,
  }),
  loginSuccess: (payload) => ({
    type: actionTypes.LOGIN_SUCCESS,
    payload,
  }),
  logout: () => ({
    type: actionTypes.LOGOUT,
  }),
  logoutSuccess: () => ({
    type: actionTypes.LOGOUT_SUCCESS,
  }),

  forgotPassword: (payload) => ({
    type: actionTypes.FORGOT_PASSWORD,
    payload,
  }),
  forgotPasswordSuccess: (payload) => ({
    type: actionTypes.FORGOT_PASSWORD_SUCCESS,
    payload,
  }),
  startLoading: () => ({
    type: actionTypes.START_LOADING,
  }),
  stopLoading: () => ({
    type: actionTypes.STOP_LOADING,
  }),
  //CLASS

  getAllClasses: () => ({
    type: actionTypes.GET_ALL_CLASSES,
  }),
  getAllClassesSuccess: (payload) => ({
    type: actionTypes.GET_ALL_CLASSES_SUCCESS,
    payload,
  }),
  addClass: (payload) => ({
    type: actionTypes.ADD_CLASS,
    payload,
  }),
  addClassSuccess: (payload) => ({
    type: actionTypes.ADD_CLASS_SUCCESS,
    payload,
  }),
  deleteClass: (payload) => ({
    type: actionTypes.DELETE_CLASS,
    payload,
  }),
  deleteClassSuccess: (payload) => ({
    type: actionTypes.DELETE_CLASS_SUCCESS,
    payload,
  }),
  updateClass: (payload) => ({
    type: actionTypes.UPDATE_CLASS,
    payload,
  }),
  updateClassSuccess: (payload) => ({
    type: actionTypes.UPDATE_CLASS_SUCCESS,
    payload,
  }),
  editClass: (payload) => ({
    type: actionTypes.EDIT_CLASS,
    payload,
  }),
  editClassSuccess: (payload) => ({
    type: actionTypes.EDIT_CLASS_SUCCESS,
    payload,
  }),

  // STUDENTS
  getAllStudents: () => ({
    type: actionTypes.GET_ALL_STUDENTS,
  }),
  getAllStudentsSuccess: (payload) => ({
    type: actionTypes.GET_ALL_STUDENTS_SUCCESS,
    payload,
  }),
  addStudent: (payload) => ({
    type: actionTypes.ADD_STUDENT,
    payload,
  }),
  addStudentSuccess: (payload) => ({
    type: actionTypes.ADD_STUDENT_SUCCESS,
    payload,
  }),
  deleteStudent: (payload) => ({
    type: actionTypes.DELETE_STUDENT,
    payload,
  }),
  deleteStudentSuccess: (payload) => ({
    type: actionTypes.DELETE_STUDENT_SUCCESS,
    payload,
  }),
  editStudent: (payload) => ({
    type: actionTypes.EDIT_STUDENT,
    payload,
  }),
  editStudentSuccess: (payload) => ({
    type: actionTypes.EDIT_STUDENT_SUCCESS,
    payload,
  }),
  editStudentPersonalDetails: (payload) => ({
    type: actionTypes.EDIT_STUDENT_PERSONAL_DETAILS,
    payload,
  }),
  editStudentPersonalDetailsSuccess: (payload) => ({
    type: actionTypes.EDIT_STUDENT_PERSONAL_DETAILS_SUCCESS,
    payload,
  }),
  assignStudentToClassSuccess: (payload) => ({
    type: actionTypes.ASSIGN_STUDENT_TO_CLASS_SUCCESS,
    payload,
  }),
  // TEACHERS
  getAllTeachers: () => ({
    type: actionTypes.GET_ALL_TEACHERS,
  }),
  getAllTeachersSuccess: (payload) => ({
    type: actionTypes.GET_ALL_TEACHERS_SUCCESS,
    payload,
  }),
  addTeacher: (payload) => ({
    type: actionTypes.ADD_TEACHER,
    payload,
  }),
  addTeacherSuccess: (payload) => ({
    type: actionTypes.ADD_TEACHER_SUCCESS,
    payload,
  }),
  deleteTeacher: (payload) => ({
    type: actionTypes.DELETE_TEACHER,
    payload,
  }),
  deleteTeacherSuccess: (payload) => ({
    type: actionTypes.DELETE_TEACHER_SUCCESS,
    payload,
  }),
  editTeacher: (payload) => ({
    type: actionTypes.EDIT_TEACHER,
    payload,
  }),
  editTeacherSuccess: (payload) => ({
    type: actionTypes.EDIT_TEACHER_SUCCESS,
    payload,
  }),
  classAssignedSuccess: (payload) => ({
    type: actionTypes.CLASS_ASSIGNED_SUCCESS,
    payload,
  }),

  // SUBJECTS
  getAllSubjects: () => ({
    type: actionTypes.GET_ALL_SUBJECTS,
  }),
  getAllSubjectsSuccess: (payload) => ({
    type: actionTypes.GET_ALL_SUBJECTS_SUCCESS,
    payload,
  }),
  addSubject: (payload) => ({
    type: actionTypes.ADD_SUBJECT,
    payload,
  }),
  addSubjectSuccess: (payload) => ({
    type: actionTypes.ADD_SUBJECT_SUCCESS,
    payload,
  }),
  deleteSubject: (payload) => ({
    type: actionTypes.DELETE_SUBJECT,
    payload,
  }),
  deleteSubjectSuccess: (payload) => ({
    type: actionTypes.DELETE_SUBJECT_SUCCESS,
    payload,
  }),
  editSubject: (payload) => ({
    type: actionTypes.EDIT_SUBJECT,
    payload,
  }),
  editSubjectSuccess: (payload) => ({
    type: actionTypes.EDIT_SUBJECT_SUCCESS,
    payload,
  }),
  // notices
  getAllNotices: () => ({
    type: actionTypes.GET_ALL_NOTICES,
  }),
  getAllNoticesSuccess: (payload) => ({
    type: actionTypes.GET_ALL_NOTICES_SUCCESS,
    payload,
  }),
  addNotice: (payload) => ({
    type: actionTypes.ADD_NOTICE,
    payload,
  }),
  addNoticeSuccess: (payload) => ({
    type: actionTypes.ADD_NOTICE_SUCCESS,
    payload,
  }),
  deleteNotice: (payload) => ({
    type: actionTypes.DELETE_NOTICE,
    payload,
  }),

  deleteNoticeSuccess: (payload) => ({
    type: actionTypes.DELETE_NOTICE_SUCCESS,
    payload,
  }),
  editNotice: (payload) => ({
    type: actionTypes.EDIT_NOTICE,
    payload,
  }),
  editNoticeSuccess: (payload) => ({
    type: actionTypes.EDIT_NOTICE_SUCCESS,
    payload,
  }),
  //attendance
  getAllAttendance: () => ({
    type: actionTypes.GET_ALL_ATTENDANCE,
  }),
  getAllAttendanceSuccess: (payload) => ({
    type: actionTypes.GET_ALL_ATTENDANCE_SUCCESS,
    payload,
  }),
  //payment
  getAllPayments: () => ({
    type: actionTypes.GET_ALL_PAYMENT,
  }),
  getAllPaymentsSuccess: (payload) => ({
    type: actionTypes.GET_ALL_PAYMENT_SUCCESS,
    payload,
  }),
  // evemts
  getAllEvents: () => ({
    type: actionTypes.GET_ALL_EVENTS,
  }),
  getAllEventsSuccess: (payload) => ({
    type: actionTypes.GET_ALL_EVENTS_SUCCESS,
    payload,
  }),
  addEvent: (payload) => ({
    type: actionTypes.ADD_EVENT,
    payload,
  }),
  addEventSuccess: (payload) => ({
    type: actionTypes.ADD_EVENT_SUCCESS,
    payload,
  }),
  deleteEvent: (payload) => ({
    type: actionTypes.DELETE_EVENT,
    payload,
  }),
  deleteEventSuccess: (payload) => ({
    type: actionTypes.DELETE_EVENT_SUCCESS,
    payload,
  }),
  editEvent: (payload) => ({
    type: actionTypes.EDIT_EVENT,
    payload,
  }),
  editEventSuccess: (payload) => ({
    type: actionTypes.EDIT_EVENT_SUCCESS,
    payload,
  }),
  //fees
  setMonthlyAmountForAClass: (payload) => ({
    type: actionTypes.SET_MONTHLY_FEES_FOR_A_CLASS,
    payload,
  }),
  // store
  getAllStoreRecords: () => ({
    type: actionTypes.GET_ALL_STORE_RECORDS,
  }),
  getAllStoreRecordsSuccess: (payload) => ({
    type: actionTypes.GET_ALL_STORE_RECORDS_SUCCESS,
    payload,
  }),
  addStoreRecord: (payload) => ({
    type: actionTypes.ADD_STORE_RECORD,
    payload,
  }),
  addStoreRecordSuccess: (payload) => ({
    type: actionTypes.ADD_STORE_RECORD_SUCCESS,

    payload,
  }),
  deleteStoreRecord: (payload) => ({
    type: actionTypes.DELETE_STORE_RECORD,
    payload,
  }),
  deleteStoreRecordSuccess: (payload) => ({
    type: actionTypes.DELETE_STORE_RECORD_SUCCESS,
    payload,
  }),
  editStoreRecord: (payload) => ({
    type: actionTypes.EDIT_STORE_RECORD,
    payload,
  }),
  editStoreRecordSuccess: (payload) => ({
    type: actionTypes.EDIT_STORE_RECORD_SUCCESS,
    payload,
  }),
};
export default actions;
