import { useSelector } from "react-redux";

export const useInfoSelector = () =>
  useSelector((state) => state.userReducer.user);
export const useLoadingSelector = () =>
  useSelector((state) => state.userReducer.loading);
export const useClassSelector = () =>
  useSelector((state) => state.classRoomReducer.classes);
export const useStudentsSelector = () =>
  useSelector((state) => state.studentsReducer.students);
export const useStudentLoadingSelector = () =>
  useSelector((state) => state.studentsReducer.loading);
export const useTeachersSelector = () =>
  useSelector((state) => state.teachersReducer.teachers);
export const useTeacherLoadingSelector = () =>
  useSelector((state) => state.teachersReducer.loading);
export const useSubjectsSelector = (id) =>
  useSelector(
    (state) =>
      state.classRoomReducer.classes.filter((item) => item?.classId === id)[0]
        ?.subjects
  );
export const useNoticeSelector = () =>
  useSelector((state) => state.noticeReducer.notices);
export const useAttendanceSelector = () =>
  useSelector((state) => state.attendanceReducer.attendance);
export const usePaymentSelector = () =>
  useSelector((state) => state.paymentReducer.payment);
export const useEventsSelector = () =>
  useSelector((state) => state.eventsReducer.events);
export const useStoreRecordSelector = () =>
  useSelector((state) => state.storeRecordReducer.records);
