import { lazy, Suspense } from "react";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
  Navigate,
} from "react-router-dom";
import { useState } from "react";
import Loading from "./utils/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useInfoSelector } from "./redux/selectors";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "./redux/actions";
// import Teachers from "./screens/Teachers/Teachers";

// import Class from "./screens/Class";
// import Sidebar from "./components/Sidebar";
import Dashboard from "./screens/Dashboard";
import { getDatabase, onValue, ref } from "firebase/database";

// import Students from "./screens/Students/Students";
// import StudentView from "./screens/Students/StudentView";
// import Notices from "./screens/Notices/Notices";
// import Attendance from "./components/Attendance/Attendance";
// import ClassView from "./screens/ClassView";
// // import SignIn from "./screens/Auth/SignIn";
// import Register from "./screens/Auth/Register";
// import ForgotPassword from "./screens/Auth/ForgotPassword";
// import PostNotice from "./screens/Notices/PostNotice";

const Sidebar = lazy(() => import("./components/Sidebar"));
// const Dashboard = lazy(() => import("./screens/Dashboard"));
const Class = lazy(() => import("./screens/Class"));
const Students = lazy(() => import("./screens/Students/Students"));
const Notices = lazy(() => import("./screens/Notices/Notices"));
const Attendance = lazy(() => import("./components/Attendance/Attendance"));
const ClassView = lazy(() => import("./screens/ClassView"));
const SignIn = lazy(() => import("./screens/Auth/SignIn"));
const Register = lazy(() => import("./screens/Auth/Register"));
const ForgotPassword = lazy(() => import("./screens/Auth/ForgotPassword"));
const PostNotice = lazy(() => import("./screens/Notices/PostNotice"));
const StudentView = lazy(() => import("./screens/Students/StudentView"));
const Teachers = lazy(() => import("./screens/Teachers/Teachers"));
const TeacherView = lazy(() => import("./screens/Teachers/TeacherView"));
const PaymentHistoryScreen = lazy(() =>
  import("./screens/PaymentHistoryScreen")
);
const StoreManagement = lazy(() => import("./screens/StoreManagement"));

function Pages() {
  const db = getDatabase();
  const dispatch = useDispatch();
  const [auth, setAuth] = useState(false);
  const user = useInfoSelector();

  useEffect(() => {
    if (!user.email) return;
    dispatch(actions.getAllClasses());
    dispatch(actions.getAllStudents());
    dispatch(actions.getAllTeachers());
    dispatch(actions.getAllNotices());
    onValue(ref(db, "attendance_list"), (snapshot) => {
      const attendance = snapshot.val();
      dispatch(actions.getAllAttendanceSuccess(attendance));
    });
    dispatch(actions.getAllPayments());
    dispatch(actions.getAllEvents());
    dispatch(actions.getAllStoreRecords());
    onValue(ref(db, "store_records"), () => {
      dispatch(actions.getAllStoreRecords());
    });
  }, [user.email]);

  // console.log("User==>>", user);
  //   console.log(auth);
  return (
    <React.Fragment>
      <ToastContainer />
      {!user?.email ? (
        <Suspense fallback={<Loading />}>
          <BrowserRouter>
            {/* <SignIn setAuth={setAuth} /> */}
            <Routes>
              <Route path="/dashboard" element={<Navigate to="/" />} />
              <Route path="*" element={<h1>Error page not found.</h1>} />
              <Route path="/" element={<SignIn setAuth={setAuth} />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      ) : (
        <div className="AppGlass">
          <Suspense fallback={<Loading />}>
            <BrowserRouter>
              <Sidebar setAuth={setAuth} />

              <Routes>
                {/* {["/dashboard].map((path) => (
                  <Route key={path} path={path} element={<Dashboard />} />
                ))} */}
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />

                <Route path="/class" element={<Class />} />
                <Route path="/students" element={<Students />} />
                <Route path="/notices" element={<Notices />} />
                <Route path="/notices/post-notice" element={<PostNotice />} />
                {/* <Route path="/attendance" element={<Attendance />} /> */}
                {/* <Route path="/students/id" element={<StudentView />} />
                 */}
                <Route path="/teachers" element={<Teachers />} />
                <Route path="/teachers/:id" element={<TeacherView />} />
                <Route path="/students/:id" element={<StudentView />} />
                <Route path="/class/:id" element={<ClassView />} />
                <Route
                  path="/payment-history"
                  element={<PaymentHistoryScreen />}
                />
                <Route path="/store-management" element={<StoreManagement />} />

                {/* 
            <Route exact path="/about" component={About} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/contact" component={Contact} /> */}
                {/* <Route path="/signIn" element={<SignIn />} /> */}
              </Routes>
              {/* <Footer /> */}
            </BrowserRouter>
          </Suspense>
        </div>
      )}
    </React.Fragment>
  );
}
function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default Pages;
