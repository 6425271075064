import { actions, actionTypes } from "../actions";
const initialState = {
  students: [],
  loading: false,
  error: null,
};
const studentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_STUDENTS_SUCCESS:
      console.log("done");
      return {
        ...state,
        students: action.payload,
        loading: false,
      };
    case actionTypes.ADD_STUDENT_SUCCESS:
      return {
        ...state,
        students: [action.payload, ...state.students],
        loading: false,
      };
    case actionTypes.DELETE_STUDENT_SUCCESS:
      console.log("Deleted", action.payload);
      return {
        ...state,
        students: state.students.filter(
          (student) => student._id !== action.payload
        ),
        loading: false,
      };
    case actionTypes.EDIT_STUDENT_SUCCESS:
      return {
        ...state,
        students: state.students.map((student) =>
          student._id === action.payload._id
            ? { ...student, ...action.payload }
            : student
        ),
        loading: false,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default studentsReducer;
