const actionTypes = {
  // Auth
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  // Loading
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",
  // Class
  GET_ALL_CLASSES: "GET_ALL_CLASSES",
  GET_ALL_CLASSES_SUCCESS: "GET_ALL_CLASSES_SUCCESS",
  ADD_CLASS: "ADD_CLASS",
  ADD_CLASS_SUCCESS: "ADD_CLASS_SUCCESS",
  EDIT_CLASS: "EDIT_CLASS",
  EDIT_CLASS_SUCCESS: "EDIT_CLASS_SUCCESS",
  DELETE_CLASS: "DELETE_CLASS",
  DELETE_CLASS_SUCCESS: "DELETE_CLASS_SUCCESS",
  UPDATE_CLASS: "UPDATE_CLASS",
  UPDATE_CLASS_SUCCESS: "UPDATE_CLASS_SUCCESS",

  // STUDENTS
  GET_ALL_STUDENTS: "GET_ALL_STUDENTS",
  GET_ALL_STUDENTS_SUCCESS: "GET_ALL_STUDENTS_SUCCESS",
  ADD_STUDENT: "ADD_STUDENT",
  ADD_STUDENT_SUCCESS: "ADD_STUDENT_SUCCESS",
  EDIT_STUDENT: "EDIT_STUDENT",
  EDIT_STUDENT_SUCCESS: "EDIT_STUDENT_SUCCESS",
  DELETE_STUDENT: "DELETE_STUDENT",
  EDIT_STUDENT_PERSONAL_DETAILS: "EDIT_STUDENT_PERSONAL_DETAILS",
  EDIT_STUDENT_PERSONAL_DETAILS_SUCCESS:
    "EDIT_STUDENT_PERSONAL_DETAILS_SUCCESS",
  DELETE_STUDENT_SUCCESS: "DELETE_STUDENT_SUCCESS",
  ADD_STUDENT_LOADING_START: "ADD_STUDENT_LOADING_START",
  ADD_STUDENT_LOADING_STOP: "ADD_STUDENT_LOADING_STOP",
  ASSIGN_STUDENT_TO_CLASS: "ASSIGN_STUDENT_TO_CLASS",
  ASSIGN_STUDENT_TO_CLASS_SUCCESS: "ASSIGN_STUDENT_TO_CLASS_SUCCESS",
  // TEACHERS
  GET_ALL_TEACHERS: "GET_ALL_TEACHERS",
  GET_ALL_TEACHERS_SUCCESS: "GET_ALL_TEACHERS_SUCCESS",
  ADD_TEACHER: "ADD_TEACHER",
  ADD_TEACHER_SUCCESS: "ADD_TEACHER_SUCCESS",
  EDIT_TEACHER: "EDIT_TEACHER",
  EDIT_TEACHER_SUCCESS: "EDIT_TEACHER_SUCCESS",
  DELETE_TEACHER: "DELETE_TEACHER",
  DELETE_TEACHER_SUCCESS: "DELETE_TEACHER_SUCCESS",
  CLASS_ASSIGNED_SUCCESS: "CLASS_ASSIGNED_SUCCESS",

  // SUBJECTS
  GET_ALL_SUBJECTS: "GET_ALL_SUBJECTS",
  GET_ALL_SUBJECTS_SUCCESS: "GET_ALL_SUBJECTS_SUCCESS",
  ADD_SUBJECT: "ADD_SUBJECT",
  ADD_SUBJECT_SUCCESS: "ADD_SUBJECT_SUCCESS",
  EDIT_SUBJECT: "EDIT_SUBJECT",
  EDIT_SUBJECT_SUCCESS: "EDIT_SUBJECT_SUCCESS",
  DELETE_SUBJECT: "DELETE_SUBJECT",
  DELETE_SUBJECT_SUCCESS: "DELETE_SUBJECT_SUCCESS",
  // NOTICES
  GET_ALL_NOTICES: "GET_ALL_NOTICES",
  GET_ALL_NOTICES_SUCCESS: "GET_ALL_NOTICES_SUCCESS",
  ADD_NOTICE: "ADD_NOTICE",
  ADD_NOTICE_SUCCESS: "ADD_NOTICE_SUCCESS",
  EDIT_NOTICE: "EDIT_NOTICE",
  EDIT_NOTICE_SUCCESS: "EDIT_NOTICE_SUCCESS",
  DELETE_NOTICE: "DELETE_NOTICE",
  DELETE_NOTICE_SUCCESS: "DELETE_NOTICE_SUCCESS",
  //Attendance
  GET_ALL_ATTENDANCE: "GET_ALL_ATTENDANCE",
  GET_ALL_ATTENDANCE_SUCCESS: "GET_ALL_ATTENDANCE_SUCCESS",
  // Payment
  GET_ALL_PAYMENT: "GET_ALL_PAYMENT",
  GET_ALL_PAYMENT_SUCCESS: "GET_ALL_PAYMENT_SUCCESS",
  // Events
  GET_ALL_EVENTS: "GET_ALL_EVENTS",
  GET_ALL_EVENTS_SUCCESS: "GET_ALL_EVENTS_SUCCESS",
  ADD_EVENT: "ADD_EVENT",
  ADD_EVENT_SUCCESS: "ADD_EVENT_SUCCESS",
  EDIT_EVENT: "EDIT_EVENT",
  EDIT_EVENT_SUCCESS: "EDIT_EVENT_SUCCESS",
  DELETE_EVENT: "DELETE_EVENT",
  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
  //Fees
  SET_MONTHLY_FEES_FOR_A_CLASS: "SET_MONTHLY_FEES_FOR_A_CLASS",
  // Store_management
  GET_ALL_STORE_RECORDS: "GET_ALL_STORE_RECORDS",
  GET_ALL_STORE_RECORDS_SUCCESS: "GET_ALL_STORE_RECORDS_SUCCESS",
  ADD_STORE_RECORD: "ADD_STORE_RECORD",
  ADD_STORE_RECORD_SUCCESS: "ADD_STORE_RECORD_SUCCESS",
  EDIT_STORE_RECORD: "EDIT_STORE_RECORD",
  EDIT_STORE_RECORD_SUCCESS: "EDIT_STORE_RECORD_SUCCESS",
  DELETE_STORE_RECORD: "DELETE_STORE_RECORD",
  DELETE_STORE_RECORD_SUCCESS: "DELETE_STORE_RECORD_SUCCESS",
};

export default actionTypes;
