import {
  UilEstate,
  UilClipboardAlt,
  UilUsersAlt,
  UilPackage,
  UilChart,
  UilBookAlt,
  UilBookReader,
  UilMoneyBill,
  UilSignOutAlt,
  UilMegaphone,
  UilBill,
  UilGraduationCap,
  UilStore,
} from "@iconscout/react-unicons";
import { FaUserTie } from "react-icons/fa";
import { HiOutlineUserGroup } from "react-icons/hi";
// Analytics Cards imports
import { UilUsdSquare, UilMoneyWithdrawal } from "@iconscout/react-unicons";
import { keyboard } from "@testing-library/user-event/dist/keyboard";

// Recent Card Imports
import img1 from "../imgs/img1.png";
import img2 from "../imgs/img2.png";
import img3 from "../imgs/img3.png";
// const Teacher = () => {
//   return <FontAwesomeIcon icon="fa-regular fa-user-tie" />;
// };

// Sidebar Data
export const SidebarData = [
  {
    icon: UilEstate,
    heading: "Dashboard",
    link: "/dashboard",
  },
  // {
  //   icon: UilClipboardAlt,
  //   heading: "Exams",
  //   link: "/exams",
  // },
  {
    icon: UilUsersAlt,
    heading: "Students",
    link: "/students",
  },
  {
    icon: FaUserTie,
    size: 20,
    heading: "Teachers",
    link: "/teachers",
  },
  {
    icon: UilGraduationCap,
    heading: "Classes",
    link: "/class",
  },
  {
    icon: UilMegaphone,
    heading: "Notices",
    link: "/notices",
  },
  {
    icon: UilBill,
    heading: "Payments",
    link: "/payment-history",
  },
  {
    icon: UilStore,
    heading: "Store Management",
    link: "/store-management",
  },

  // {
  //   icon: UilChart,
  //   heading: "Analytics",
  //   link: "/analytics",
  // },
  {
    icon: UilSignOutAlt,
    heading: "Logout",
    link: "/",
  },
];

// Analytics Cards Data
export const cardsData = [
  {
    title: "Fees collected",
    color: {
      backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
      boxShadow: "0px 10px 20px 0px #e0c6f5",
    },
    barValue: 70,
    value: "25,970",
    png: UilUsdSquare,
    series: [
      {
        name: "Fees collected",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
    ],
  },
  {
    title: "salary paid",
    color: {
      backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
      boxShadow: "0px 10px 20px 0px #FDC0C7",
    },
    barValue: 80,
    value: "14,270",
    png: UilMoneyWithdrawal,
    series: [
      {
        name: "Salary paid",
        data: [10, 100, 50, 70, 80, 30, 40],
      },
    ],
  },
  {
    title: "Expenses",
    color: {
      backGround:
        "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
      boxShadow: "0px 10px 20px 0px #F9D59B",
    },
    barValue: 60,
    value: "4,270",
    png: UilClipboardAlt,
    series: [
      {
        name: "Expenses",
        data: [10, 25, 15, 30, 12, 15, 20],
      },
    ],
  },
];

// Recent Update Card Data
export const Noticesdata = [
  {
    img: img1,
    title: "Exam cancelled",
    description: "The Exam has been cancelled.",
    time: "25 seconds ago",
    date: "9/12/2022",
    status: "pending",
    id: 1,
    by: "Biju Dey",
  },
  {
    img: img2,
    title: "Exam cancelled",
    description: "The Exam has been cancelled.",
    time: "25 seconds ago",
    date: "9/12/2022",
    status: "approved",
    id: 2,
    by: "Sandeep Kumar",
  },
  {
    img: img3,
    title: "Exam cancelled",
    description: "The Exam has been cancelled.",
    time: "25 seconds ago",
    date: "9/12/2022",
    status: "approved",
    id: 3,
    by: "Biproraj Das",
  },
  {
    img: img3,
    title: "Exam cancelled",
    description: "The Exam has been cancelled.",
    time: "25 seconds ago",
    date: "9/12/2022",
    status: "approved",
    id: 4,
    by: "Biproraj Das",
  },
];

// Data of classes
export const classData = [
  {
    id: 1,
    name: "1",
    teacher: "Mr. Biju Dey",
    students: "20",
    exams: "2",
  },
  {
    id: 2,
    name: "2",
    teacher: "Mr. Sandeep Kumar",
    students: "20",
    exams: "2",
  },
  {
    id: 3,
    name: "3",
    teacher: "Mr. Biproraj Das",
    students: "20",
    exams: "2",
  },
];
export const students = [
  {
    name: "Biju Dey",
    id: "1",
    attendance: "80%",
    fee: "Paid",
    password: "123456",
    class: "1",
    email: "biju64dey@gmail.com",
    parent: "Mr. Parent",
    parentPhone: "9876543210",
    address: "Karbi Anglong, India",
    dob: "25/01/2003",
    admissionNumber: "5485581",
  },
  {
    name: "Sandeep Kumar",
    id: "2",
    password: "123",
    class: "2",
    fee: "Unpaid",
    email: " sandy456@gmail.com",
    parent: "Mr. Parent",
    parentPhone: "1234567890",
    address: "Nagaland",
    dob: "25/01/2003",
    admissionNumber: "5485582",
  },
  {
    name: "Biproraj Das",
    id: "3",
    class: "3",
    fee: "Paid",
    email: "Biprorajdas600@gmail.com",
    parent: "Mr. Parent",
    parentPhone: "9876543210",
    address: "Shilchar",
    dob: "25/01/2003",
    admissionNumber: "",
  },
  {
    name: "Shubhankur Kashyup",
    id: "4",
    class: "",
    fee: "Paid",
    email: "ShubhankurKashyap@gmail.com",
    parent: "Mr. Parent",
    parentPhone: "9876543210",
    address: "Kolkata",
    dob: "25/01/2003",
    admissionNumber: "5485584",
  },
];
export const attendance = [
  {
    id: "0",
    studentId: "1",
    date: "02/01/2021",
    isPresent: "Present",
    time: "9:00 AM",
  },
  {
    id: "1",
    studentId: "1",
    date: "03/01/2021",
    isPresent: "Absent",
    time: "9:01 AM",
  },
  {
    id: "2",
    studentId: "2",
    date: "03/01/2021",
    isPresent: "Present",
    time: "9:00 AM",
  },
  {
    id: "3",
    studentId: "3",
    date: "04/01/2021",
    isPresent: "Present",
    time: "10:00 AM",
  },
  {
    id: "4",
    studentId: "4",
    date: "05/01/2021",
    isPresent: "Present",
    time: "9:30 AM",
  },
];
export const classViewCard = [
  {
    id: "1",
    label: "Total students",
    icon: UilBookReader,
    color: {
      backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
      boxShadow: "0px 10px 20px 0px #e0c6f5",
    },
  },
  // {
  //   id:"2",
  //   label:"Total Exams",
  //   color: {
  //     backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
  //     boxShadow: "0px 10px 20px 0px #FDC0C7",
  //   },
  //   number:"2",
  // },
  {
    id: "3",
    label: "Total Fees Collected",
    icon: UilMoneyBill,
    color: {
      backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
      boxShadow: "0px 10px 20px 0px #FDC0C7",
    },
  },
  {
    id: "4",
    label: "Total Subjects",
    icon: UilBookAlt,
    color: {
      backGround:
        "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
      boxShadow: "0px 10px 20px 0px #F9D59B",
    },
    number: "2",
  },
];
export const allTeachers = [
  {
    id: "1",
    name: "Biju Dey",
    email: "email@gmal.com",
    phone: "9876543210",
    address: "Some Place",
    dob: "25/01/2003",
    qualification: "B.Tech",
  },
  {
    id: "2",
    name: "Sandeep Kumar",
    email: "email@gmal.com",
    phone: "9876543210",
    address: "Some Place",
    dob: "25/01/2003",
    qualification: "B.A",
  },
  {
    id: "3",
    name: "Biproraj Das",
    email: "email@gmal.com",
    phone: "9876543210",
    address: "Some Place",
    dob: "25/01/2003",
    qualification: "B.Sc",
  },
  {
    id: "4",
    name: "Shubhankur Kashyup",
    email: "email@gmal.com",
    phone: "9876543210",
    address: "Some Place",
    dob: "25/01/2003",
    qualification: "B.Com",
  },
];
export const paymentDetails = [
  {
    id: "1",
    month: "January",
    amount: "1000",
    status: "Paid",
    date: "01/01/2021",
  },
  {
    id: "2",
    month: "February",
    amount: "1000",
    status: "Paid",
    date: "01/02/2021",
  },
  {
    id: "3",
    month: "March",
    amount: "1000",
    status: "Paid",
    date: "01/03/2021",
  },
  {
    id: "4",
    month: "April",
    status: "Unpaid",
    amount: "1000",
    date: "01/04/2021",
  },
];
export const salaryDetails = [
  {
    id: "1",
    month: "January",
    amount: "25000",
    status: "Paid",
    date: "01/01/2021",
  },
  {
    id: "2",
    month: "February",
    amount: "1000",
    status: "Paid",
    date: "01/02/2021",
  },
  {
    id: "3",
    month: "March",
    amount: "1000",
    status: "Paid",
    date: "01/03/2021",
  },
  {
    id: "4",
    month: "April",
    status: "Unpaid",
    amount: "1000",
    date: "01/04/2021",
  },
];
export const classesAssigned = [
  {
    id: "1",
    class: "Class 1",
    subject: "English",
    time: "9:00 AM - 10:00 AM",
  },
  {
    id: "4",
    class: "Class 4",
    subject: "Social Science",

    time: "10:00 AM - 11:00 AM",
  },

  {
    id: "3",
    class: "Class 3",
    subject: "Science",
    time: "11:00 AM - 12:00 PM",
  },

  {
    id: "2",
    class: "Class 2",
    subject: "Maths",
    time: "2:00 PM - 3:00 PM",
  },
];
