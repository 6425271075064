import { takeLatest, put, all, call } from "redux-saga/effects";

import { actions, actionTypes } from "../actions";
import { auth } from "../../firebase/utils";
import {
  getDatabase,
  ref,
  get,
  child,
  set,
  push,
  serverTimestamp,
  remove,
  onValue,
  update,
} from "firebase/database";
import showToast from "../../utils/functions";
import { Navigate, NavLink, redirect, useNavigate } from "react-router-dom";
import { URLS } from "../../utils/urls";

const db = getDatabase();
const url = URLS.REACT_APP_API_URL_SIGNUP;

function* getAllTeachers({ payload }) {
  try {
    const response = yield get(child(ref(db), `teachers`));
    const responseArr = yield response.val()
      ? Object.keys(response.val()).map((key) => ({
          ...response.val()[key],
        }))
      : [];
    yield put(actions.getAllTeachersSuccess(responseArr));
    yield console.log("Data==>", responseArr);
  } catch (error) {
    console.log(error);
  }
}

function* addTeachers({ payload }) {
  try {
    const handleClose = payload.handleClose;
    delete payload.handleClose;
    yield put(actions.startLoading());
    const response = yield call(fetch, url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...payload,
        user_type: "teachers",
        roll_no: "10",
      }),
    });
    const data = yield response.json();
    console.log(data);
    if (data.message == "teachers signed up successfully 🥳") {
      showToast("Teacher Added Successfully", "success");
      yield put(actions.addTeacherSuccess({ ...data.data.userData }));
      yield handleClose();
    } else {
      showToast("Teacher Already Exist", "error");
    }
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(actions.stopLoading());
    console.log(error);
  }
}
function* deleteTeacher({ payload }) {
  try {
    const id = payload;
    yield put(actions.startLoading());
    yield remove(ref(db, `teachers/${payload}`));
    yield put(actions.deleteTeacherSuccess(id));
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(actions.stopLoading());
    console.log(error);
  }
}
function* editTeacher({ payload }) {
  try {
    const handleClose = payload.handleClose;
    delete payload.handleClose;
    const extractedPayload = Object.keys(payload).reduce((acc, key) => {
      if (payload[key] !== "") {
        acc[key] = payload[key];
      }
      return acc;
    }, {});
    console.log(extractedPayload, payload);
    yield update(ref(db, `teachers/${payload._id}`), extractedPayload);

    yield showToast("Teacher Updated Successfully", "success");
    yield put(actions.editTeacherSuccess(extractedPayload));
    handleClose();
  } catch (error) {
    console.log(error);
  }
}

export default function* watchTeachers() {
  yield takeLatest(actionTypes.ADD_TEACHER, addTeachers);
  yield takeLatest(actionTypes.GET_ALL_TEACHERS, getAllTeachers);
  yield takeLatest(actionTypes.DELETE_TEACHER, deleteTeacher);
  yield takeLatest(actionTypes.EDIT_TEACHER, editTeacher);
}
