import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { UilImport } from "@iconscout/react-unicons";
import { paymentDetails } from "../Data/Data";
import { salaryDetails } from "../Data/Data";
import Invoice from "./Invoice";

const { forwardRef, useRef, useImperativeHandle } = React;

function PaymentHIstoryTable({ pageName }) {
  const downloadRef = useRef();
  return (
    <div className="payment_history_table">
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Month</TableCell>
              <TableCell align="center">Amount</TableCell>
              {/* <TableCell align="right">Status</TableCell> */}
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(pageName == "Students" ? paymentDetails : salaryDetails).map(
              (row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.month}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {row.amount}
                  </TableCell>
                  {/* <TableCell component="th" align="right">
                  <span
                    style={
                      row.status === "Paid"
                        ? {
                            backgroundColor: "#7ed320",
                            padding: 6,
                            fontSize: 12,
                            borderRadius: 50,
                          }
                        : {
                            backgroundColor: "#ff1717",
                            padding: 6,
                            fontSize: 12,
                            color: "white",
                            borderRadius: 50,
                          }
                    }
                  >
                    {row.status}
                  </span>
                </TableCell> */}
                  <TableCell component="th" align="center">
                    <Button
                      variant="contained"
                      //row.status==="paid" then onclick
                      onClick={() => {
                        row.status === "Paid" &&
                          downloadRef.current.generatePDF();
                      }}
                      // disabled={row.status === "Paid" ? true : false}
                      // onclick if paid it will generate the reciept and will show the transaction id, if not paid it will show the payment gateway or button for offline payment.
                      style={
                        row.status === "Paid"
                          ? {
                              backgroundColor: "#437fe7",
                              color: "white",
                              borderRadius: 50,
                              padding: 6,
                              fontSize: 12,
                              textTransform: "none",
                            }
                          : {
                              backgroundColor: "#7ed320",
                              color: "white",
                              borderRadius: 50,
                              padding: 6,
                              fontSize: 12,
                              textTransform: "none",
                            }
                      }
                    >
                      {row.status === "Paid" ? "Paid" : "Pay"}
                      {row.status === "Paid" && (
                        <UilImport style={{ marginLeft: 5 }} size="13" />
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        <div style={{ display: "none" }}>
          <Invoice ref={downloadRef} />
        </div>
      </TableContainer>
    </div>
  );
}

export default PaymentHIstoryTable;
