// create a saga middleware function for signup login using firebase

import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { actions, actionTypes } from "../actions";
import { auth } from "../../firebase/utils";
import { getDatabase, ref, get, child } from "firebase/database";
import showToast from "../../utils/functions";
import { Navigate, NavLink, redirect, useNavigate } from "react-router-dom";

const db = getDatabase();
// const email = "admintest@mail.com";
// const password = "password";
const displayName = "admin";

function* signUp({ payload }) {
  try {
    const { email, password } = payload;
    const { user } = yield createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    yield put(actions.signupSuccess({ user, additionalData: { displayName } }));
    console.log(user, "Done executing");
  } catch (error) {
    console.log(error);
  }
}

function* login({ payload }) {
  try {
    yield put(actions.startLoading());
    const { email, password } = payload;

    console.log(email, password);
    const { user } = yield signInWithEmailAndPassword(auth, email, password);
    const { uid } = user;

    const snapshot = yield get(child(ref(db), `user_admin`));
    const newPayload = { email, uid };
    if (snapshot.exists()) {
      if (snapshot.val().email === email) {
        yield put(actions.loginSuccess(newPayload));
        showToast("Login Success", "success");

        // navigate to dashboard page

        payload.navigate("/dashboard");
      } else {
        yield signOut(auth);
        showToast("Invalid Credentials", "error");
      }
    } else {
      console.log("No data available");
    }
    yield put(actions.stopLoading());
  } catch (error) {
    yield put(actions.stopLoading());
    showToast(error.message, "error");
    console.log(error);
  }
}

function* logout() {
  try {
    yield signOut(auth);
    yield put(actions.logoutSuccess());
  } catch (error) {
    console.log(error);
  }
}
export default function* watchUser() {
  yield takeLatest(actionTypes.SIGNUP, signUp);
  yield takeLatest(actionTypes.LOGIN, login);
  yield takeLatest(actionTypes.LOGOUT, logout);
}
