import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import Cards from "../Cards/Cards";
import EventScheduler from "../EventScheduler";
import PaymentHIstoryTable from "../PaymentHIstoryTable";
import ScrollbarWrapper from "../ScrollbarWrapper";
import JsPDF from "jspdf";

import Table from "../Table/Table";
import "./MainDash.css";
import Invoice from "../Invoice";

const MainDash = () => {
  return (
    // <ScrollbarWrapper>
    <div className="MainDash">
      <h1>Dashboard</h1>

      {/* <div style={{ marginBottom: "10px" }}>
        <Cards />
      </div> */}
      {/* <button onClick={generatePDF} type="button">
          Export PDF
        </button> */}
      {/* <Invoice /> */}

      {/* <Table /> */}

      <EventScheduler />
    </div>
    // </ScrollbarWrapper>
  );
};

export default MainDash;
