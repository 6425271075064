import { actionTypes } from "../actions";
const initialState = {
  events: [],
  loading: false,
  error: null,
};
const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
export default eventsReducer;
