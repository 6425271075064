import "./App.css";
import { lazy, Suspense } from "react";

import { useState } from "react";
import Loading from "./utils/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
// import Teachers from "./screens/Teachers/Teachers";

// import Class from "./screens/Class";
// import Sidebar from "./components/Sidebar";
// import Dashboard from "./screens/Dashboard";
// import Students from "./screens/Students/Students";
// import StudentView from "./screens/Students/StudentView";
// import Notices from "./screens/Notices/Notices";
// import Attendance from "./components/Attendance/Attendance";
// import ClassView from "./screens/ClassView";
// // import SignIn from "./screens/Auth/SignIn";
// import Register from "./screens/Auth/Register";
// import ForgotPassword from "./screens/Auth/ForgotPassword";
// import PostNotice from "./screens/Notices/PostNotice";
import store from "./redux/store";
import Pages from "./Pages";
import { userInfoSelector } from "./redux/selectors";

function App() {
  console.log("hello");
  const [auth, setAuth] = useState(false);

  console.log(auth);
  return (
    <Provider store={store}>
      <div className="App">
        <Pages />
      </div>
    </Provider>
  );
}
function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default App;
