import { actionTypes } from "../actions";

const initialState = {
  classes: [],
  loading: false,
  error: null,
};

const classRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CLASSES_SUCCESS:
      return {
        ...state,
        classes: action.payload,
        loading: false,
      };
    case actionTypes.ADD_CLASS_SUCCESS:
      console.log("State===>>>", state.classes);
      return {
        ...state,
        classes:
          state.classes !== []
            ? [...state.classes, action.payload]
            : [action.payload],

        loading: false,
      };
    case actionTypes.DELETE_CLASS_SUCCESS:
      return {
        ...state,
        classes: state.classes.filter(
          (item) => item.classId !== action.payload
        ),
        loading: false,
      };
    case actionTypes.UPDATE_CLASS_SUCCESS:
      return {
        ...state,
        classes: state.classes.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        loading: false,
      };
    case actionTypes.EDIT_CLASS_SUCCESS:
      return {
        ...state,
        classes: state.classes.map((item) =>
          item.classId === action.payload.classId
            ? { ...item, ...action.payload }
            : item
        ),
        loading: false,
      };
    case actionTypes.ADD_SUBJECT_SUCCESS:
      console.log("State===>>>", action.payload);
      return {
        ...state,
        classes: state.classes.map((item) =>
          item.classId === action.payload.classId
            ? {
                ...item,
                subjects: item?.subjects
                  ? {
                      ...item.subjects,
                      [action.payload.subjectId]: action.payload,
                    }
                  : { [action.payload.subjectId]: action.payload },
              }
            : item
        ),
        loading: false,
      };
    case actionTypes.DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        classes: state.classes.map((item) =>
          item.classId === action.payload.classId
            ? {
                ...item,
                subjects: Object.keys(item.subjects).reduce(
                  (acc, key) =>
                    key !== action.payload.subjectId
                      ? { ...acc, [key]: item.subjects[key] }
                      : acc,
                  {}
                ),
              }
            : item
        ),
        loading: false,
      };
    case actionTypes.ASSIGN_STUDENT_TO_CLASS_SUCCESS:
      return {
        ...state,
        classes: state.classes.map((item) =>
          item.classId === action.payload.classId
            ? {
                ...item,
                students: item?.students
                  ? {
                      ...item.students,
                      [action.payload.studentId]: action.payload,
                    }
                  : { [action.payload.studentId]: action.payload },
              }
            : item
        ),
        loading: false,
      };
    case actionTypes.REMOVE_STUDENT_FROM_CLASS_SUCCESS:
      return {
        ...state,
        classes: state.classes.map((item) =>
          item.classId === action.payload.classId
            ? {
                ...item,
                students: Object.keys(item.students).reduce(
                  (acc, key) =>
                    key !== action.payload.studentId
                      ? { ...acc, [key]: item.students[key] }
                      : acc,
                  {}
                ),
              }
            : item
        ),
        loading: false,
      };

    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default classRoomReducer;
