import { actionTypes } from "../actions";
const initialState = {
  attendance: [],
  loading: false,
  error: null,
};
const attendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_ATTENDANCE_SUCCESS:
      console.log("Payload", action.payload);
      return {
        ...state,
        attendance: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default attendanceReducer;
