import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { actions, actionTypes } from "../actions";
import { auth } from "../../firebase/utils";
import {
  getDatabase,
  ref,
  get,
  child,
  set,
  push,
  serverTimestamp,
  remove,
  onValue,
  update,
} from "firebase/database";
import showToast from "../../utils/functions";
import { Navigate, NavLink, redirect, useNavigate } from "react-router-dom";
const db = getDatabase();
function* addSubject({ payload }) {
  try {
    console.log("Hello from saga", payload);
    const teacher = payload.teacher;
    const classId = payload.classId;
    delete payload.classId;
    delete payload.teacher;
    const key = push(ref(db, `class_rooms/${classId}/subjects`)).key;

    const subject = {
      ...payload,
      subjectId: key,
      createdAt: serverTimestamp(),
    };
    yield set(ref(db, `class_rooms/${classId}/subjects/${key}`), subject);
    yield teacher.map((item) => {
      set(
        ref(
          db,
          `class_rooms/${classId}/subjects/${key}/teachers/${item.teacherId}`
        ),
        item
      );
      set(ref(db, `teachers/${item.teacherId}/subjects/${classId}/${key}`), {
        name: subject.name,
        subjectId: key,
      });

      // await put(
      //   actions.classAssignedSuccess({
      //     classId,
      //     teacherId: item.teacherId,
      //     subjectId: key,
      //   })
      // );
    });
    const teacherObj = teacher.reduce((acc, item) => {
      acc[item.teacherId] = item;
      return acc;
    }, {});

    yield put(
      actions.addSubjectSuccess({
        ...subject,
        classId,
        teachers: teacherObj,
      })
    );

    yield showToast("Subject Added Successfully", "success");
  } catch (error) {
    yield showToast("Subject Adding failed!", "error");
  }
}
function* deleteSubject({ payload }) {
  try {
    const { classId, subjectId, teachersArr } = payload;
    yield remove(ref(db, `class_rooms/${classId}/subjects/${subjectId}`));
    yield teachersArr.map((item) => {
      remove(
        ref(db, `teachers/${item.teacherId}/subjects/${classId}/${subjectId}`)
      );
    });
    yield put(actions.deleteSubjectSuccess({ classId, subjectId }));

    yield showToast("Subject Deleted Successfully", "success");
  } catch (error) {
    yield showToast("Subject Deleting failed!", "error");
  }
}
function* editSubject({ payload }) {
  //Edit Subject
  try {
    yield console.log("Hello from saga", payload);
    const teacher = payload.teacher;
    const classId = payload.classId;
    const subjectId = payload.subjectId;
    const filteredTeacher = payload.filteredTeacher;
    delete payload.classId;
    delete payload.teacher;
    delete payload.subjectId;
    const subject = {
      ...payload,
      subjectId: subjectId,
      createdAt: serverTimestamp(),
    };
    yield set(ref(db, `class_rooms/${classId}/subjects/${subjectId}`), subject);
    yield filteredTeacher.map((item) => {
      remove(
        ref(db, `teachers/${item.teacherId}/subjects/${classId}/${subjectId}`)
      );
    });

    yield teacher.map((item) => {
      set(
        ref(
          db,
          `class_rooms/${classId}/subjects/${subjectId}/teachers/${item.teacherId}`
        ),
        item
      );
      set(
        ref(db, `teachers/${item.teacherId}/subjects/${classId}/${subjectId}`),
        {
          name: subject.name,
          subjectId: subjectId,
        }
      );

      // await put(
      //   actions.classAssignedSuccess({
      //     classId,
      //     teacherId: item.teacherId,
      //     subjectId: key,
      //   })
      // );
    });
    const teacherObj = teacher.reduce((acc, item) => {
      acc[item.teacherId] = item;
      return acc;
    }, {});

    yield put(actions.getAllTeachers());
    yield put(actions.getAllClasses());
    yield showToast("Subject Edited Successfully", "success");
  } catch (error) {
    yield console.log(error);
    yield showToast("Subject Editing failed!", "error");
  }
}
export default function* subjectsSaga() {
  yield takeLatest(actionTypes.ADD_SUBJECT, addSubject);
  yield takeLatest(actionTypes.DELETE_SUBJECT, deleteSubject);
  yield takeLatest(actionTypes.EDIT_SUBJECT, editSubject);
}
