import { all } from "redux-saga/effects";
import watchUser from "./userSaga";
import watchClassRoom from "./classRoomSaga";
import watchStudents from "./studentsSaga";
import watchTeachers from "./teachersSaga";
import subjectsSaga from "./subjectsSaga";
import noticeSaga from "./noticeSaga";
import attendanceSaga from "./attendanceSaga";
import paymentSaga from "./paymentSaga";
import eventsSaga from "./eventsSaga";
import storeRecordSaga from "./storeRecordSaga";
export default function* rootSaga() {
  yield all([
    watchUser(),
    watchClassRoom(),
    watchStudents(),
    watchTeachers(),
    subjectsSaga(),
    noticeSaga(),
    attendanceSaga(),
    paymentSaga(),
    eventsSaga(),
    storeRecordSaga(),
  ]);
}
