import { useEffect, useState } from "react";
import { TextField, Button, DialogActions, FormControl } from "@mui/material";
import { Scheduler } from "@aldabil/react-scheduler";
import "./EventScheduler.css";
import { useDispatch } from "react-redux";
import { actions, actionTypes } from "../redux/actions";
import { useEventsSelector, useLoadingSelector } from "../redux/selectors";
const CustomEditor = ({ scheduler }) => {
  const event = scheduler.edited;
  const dispatch = useDispatch();
  const [state, setState] = useState({
    title: event?.title || "",
    description: event?.description || "",
    start: event?.start || new Date(),
    end: event?.end || new Date(),
  });
  const [error, setError] = useState("");

  const handleChange = (value, name) => {
    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleSubmit = async () => {
    if (state.title.length < 3) {
      return setError("Min 3 letters");
    }

    try {
      scheduler.loading(true);

      const added_updated_event = await new Promise((res) => {
        const setNewEvent = (eventID) => {
          res({
            event_id: event?.event_id || eventID,
            title: state.title,
            start: scheduler.state.start.value,
            end: scheduler.state.end.value,
            description: state.description,
            color: event?.color || "#f59105",
          });
        };
        const payload = {
          title: state.title,
          start: scheduler.state.start.value,
          end: scheduler.state.end.value,
          description: state.description,
          event_id: event?.event_id || Math.random(),
          color: event?.color || "#f59105",
          setNewEvent,
        };
        dispatch({
          type: event ? actionTypes.EDIT_EVENT : actionTypes.ADD_EVENT,
          payload,
        });
      });

      scheduler.onConfirm(added_updated_event, event ? "edit" : "create");
      scheduler.close();
    } finally {
      scheduler.loading(false);
    }
  };
  return (
    <div>
      <div style={{ padding: "1rem" }}>
        <TextField
          style={{ marginBottom: "1rem" }}
          label="Title"
          value={state.title}
          onChange={(e) => handleChange(e.target.value, "title")}
          error={!!error}
          helperText={error}
          fullWidth
        />
        <TextField
          label="Description"
          value={state.description}
          onChange={(e) => handleChange(e.target.value, "description")}
          fullWidth
        />
      </div>
      <DialogActions>
        <Button onClick={scheduler.close}>Cancel</Button>
        <Button onClick={handleSubmit}>Confirm</Button>
      </DialogActions>
    </div>
  );
};

const EventScheduler = () => {
  const events = useEventsSelector();
  const loading = useLoadingSelector();
  const dispatch = useDispatch();

  return !loading ? (
    <>
      <div className="title">
        <h2>Event Calendar</h2>
      </div>

      <div className="eventScheduler">
        <Scheduler
          view="month"
          events={events}
          getRemoteEvents={events}
          onDelete={(id) => {
            dispatch(actions.deleteEvent({ event_id: id }));
          }}
          customEditor={(scheduler) => <CustomEditor scheduler={scheduler} />}
          viewerExtraComponent={(fields, event) => {
            return (
              <div>
                <span>Description: {event.description || "Nothing..."}</span>
              </div>
            );
          }}
        />
      </div>
    </>
  ) : (
    <div className="title">
      <h2>Events are Loading...</h2>
    </div>
  );
};

export default EventScheduler;
